import Catalog from './../views/catalog'
import Discovery from './../views/discovery'
import ProductList from './../views/productList'
import Search from './../views/search'
import ShoppingCart from './../views/shoppingCart'
import { Route, useLocation, Routes } from 'react-router-dom'
function Content() {

    const location = useLocation()

    setTimeout(() => {
        if (location.pathname === '/shopping-cart' || location.pathname === '/descobre') 
            window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    },10)
    

    setTimeout(() => {
        const allNav = document.querySelectorAll('.main-nav a')
        allNav.forEach(nav => {
            const brokenHref = nav.href.split('/') 
            nav.classList.remove('is-active')
            if( '/' + brokenHref[brokenHref.length - 1] === location.pathname)
                nav.classList.add('is-active')
        })
    },10)

    return(
        <Routes location={location}>
            <Route path='/' element={<Catalog/>} />
            <Route path='/:id' element={<Catalog/>} />
            <Route path='/descobre' element={<Discovery/>} />
            <Route path='/promocao-produto-:id' element={<Catalog/>} />
            <Route path='/promocoes-folheto' element={<ProductList />} />
            {/* <Route path='/promocoes-folheto-:id' element={<ProductList />} /> */}
            <Route path='/search' element={<Search/>} />
            <Route path='/shopping-cart' element={<ShoppingCart/>} />
        </Routes>
    )
}

export default Content