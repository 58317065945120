import React from 'react';
import { getInputQtt } from '../utilities/helpers';
import {store} from '../utilities/store';


class QuantityInput extends React.Component {

    constructor() {
        super()
        this.state = {
            count: 1
        }
        this.plusCount = this.plusCount.bind(this)
        this.minusCount = this.minusCount.bind(this)
    }

    plusCount() {

        //NEW CART
        this.props.valueFunc(true);
        let pId = (this.props.cart ? this.props.product.id : this.props.product.data.id),
            cart = (this.props.cart ? 'cart' : 'cartTemp'),
            type = (this.props.cart ? 'updatePrd_Add' : 'add'),
            data = (this.props.cart ? this.props.product : this.props.product.data);

        store.dispatch({type: type, cart: cart, id: pId, data: data})
    }

    minusCount() {

        //NEW CART
        this.props.valueFunc(false);
        let pId = (this.props.cart ? this.props.product.id : this.props.product.data.id),
            cart = (this.props.cart ? 'cart' : 'cartTemp'),
            type = (this.props.cart ? 'updatePrd_Remove' : 'remove'),
            data = (this.props.cart ? this.props.product : this.props.product.data);

        store.dispatch({type: type, cart: cart, id: pId, data: data})
    }

    render() {

        let pId = (this.props.cart ? this.props.product.id : this.props.product.data.id),
            qtt = getInputQtt(pId, (this.props.cart ? 'cart' : 'cartTemp'));

        return (
            <div className="quantity-wrapper">
                <button title="minus button" className="minus-button" disabled={qtt === 1} onClick={this.minusCount}></button>
                <span className="product-quantity">{this.props.bulk ? (qtt * 50) + 'g' : qtt}</span>
                <button title="plus button" className="plus-button" onClick={this.plusCount}></button>
            </div>
        )
    }

}


export default QuantityInput