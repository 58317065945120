import React from "react"
import {editPrice} from '../utilities/helpers'

class SearchCard extends React.Component {

    render() {
        const cardProps = this.props.data
        const price = editPrice(cardProps.price)
        const discountPrice = cardProps.discountPrice !== -1 ? editPrice((Math.round((cardProps.discountPrice) * 100) / 100)) : editPrice((Math.round((cardProps.price) * 100) / 100))

        return (
            <div
            onClick={() => {
                document.querySelector('.product-detail-wrapper').classList.add('show')
                window.history.pushState('search Detail','','/promocao-produto-' + cardProps.id + window.location.search)
                window.dispatchEvent(new Event('popstate'));
                document.querySelector('body').classList.add('no-scroll');
            }}
            className="search-card">
                <article>
                    <figure>
                        <picture>
                            <img src={cardProps.imageUrl.shoppingCart} alt={cardProps.productName} />
                        </picture>
                        <figcaption>
                            <p className="body-5">{cardProps.productName}</p>
                            <p className="footnote-2">{cardProps.brand.brandName}</p>
                            <div className="bottom-wrapper">
                                <div className="price-wrapper">
                                    <span className="new-price body-2">{discountPrice}</span>
                                    {(cardProps.price !== 0 && cardProps.discountPrice !== -1) &&
                                        <span className="old-price footnote-1">{price}</span>
                                    }
                                </div>
                                <span className="add-button"></span>
                            </div>
                        </figcaption>
                    </figure>
                    {(cardProps.discountValue !== '' && cardProps.discountValue !== undefined && cardProps.discountValue !== 0 && cardProps.saleType === 'Percentage') ? 
                        <span className="label body-2">{cardProps.discountValue + '%'}</span>
                        : ''
                    }
                    {cardProps.saleDescriptionLabel &&
                        <span className="label body-2">
                            {
                                cardProps.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                ? cardProps.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                : cardProps.saleDescriptionLabel
                            }
                            {cardProps.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                <span className="pvpr">{cardProps.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                            }
                        </span>
                    }
                    
                </article>
            </div>
        )
    }
}

export default SearchCard
