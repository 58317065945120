import React, { useState, useEffect, useCallback} from "react"
import { Link } from "react-router-dom"
import { useInView } from 'react-intersection-observer'
import { endpoint, string_to_slug } from "../utilities/helpers"
import Cover from '../components/cover'
import Collection from "../components/collection"
import searchIcon from './../images/vectors/search.svg'
import layout1 from './../images/vectors/layout1.svg'
import layout2 from './../images/vectors/layout2.svg'
import layout3 from './../images/vectors/layout3.svg'
import border from './../images/vectors/top-border.svg'
import ProductDetail from "./productDetail"
import { useLocation } from "react-router-dom"
// Functions

function Catalogue(){
  const [collections, setCollections] = useState([])
  const [icon, setIcon] = useState(1)
  const [tags, setTags] = useState([])
  const [isReady, setIsReady] = useState(false)
  const [currentPage, setPage] = useState(1)
  const [currentHash, setHash] = useState('')
  const [prodID, setID] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const path = useLocation()

  useEffect(() => {
    setID(path.pathname.split('/promocao-produto-')[1])
    document.body.classList.toggle('no-scroll',path.pathname.includes('/promocao-produto-'))
  },[path])


  const menuCallback = (entries) => {
    const [ entry ] = entries
    
    const mainNav = document.querySelector('.main-nav')
    mainNav.classList.toggle('showing',!entry.isIntersecting)

    const wrapperHeader = document.querySelector('.catalog-wrapper header')
    if(wrapperHeader)
      wrapperHeader.classList.toggle('showing',!entry.isIntersecting)
  }

  const options = {
    threshold: 0.5
  }

  useEffect(() => {
    setTimeout(() => {
      if(currentHash !== '') {
        const idToScroll =  document.querySelector('.collection[data-id="' + currentHash + '"]')
        if(idToScroll) {
          window.scrollTo({
            top: window.scrollY + idToScroll.getBoundingClientRect().top - 80,
            left: 0
          })
        }
      }
    },200)
    
  },[currentHash])

  useEffect(() => {
    const wrapper = document.querySelector('.cover')
    const observer = new IntersectionObserver(menuCallback,options)
    observer.observe(wrapper)
  })

  useEffect(() => {
      loadCatalogue(1);
      console.log('im running');
  }, [])

  const loadCatalogue = (i) => {
    const loader = document.querySelector('.loader');

    loader.classList.add('loading')

    fetch(endpoint("/api/catalogue/get/" + i))
      .then(response => response.json())
      .then(data => {
        setCollections(data.collections)
        setIsReady(true)
        setPageNumber(data.numberOfCollections)
       
        fetch(endpoint("/api/catalogue/getcollections"))
        .then(response => response.json())
        .then(data => setTags(data))
        .then(() => {
          const localID = localStorage.getItem('lastID');
          const categoryID = window.location.pathname.substring(1);
          const itemToScroll = document.querySelector('#card-id-' + localID);
          const categoryToScroll = document.querySelector(`.collection[data-id="${categoryID}"]`);
          console.log(data, i);
          if(localID && i < data.numberOfCollections) {
            if(itemToScroll) {
              window.scrollTo({
                top: window.scrollY + itemToScroll.getBoundingClientRect().top - 80,
                left: 0
              })
            } else {
              loadCatalogue(i + 1);
            }
          } else if(categoryID && i < data.numberOfCollections) {
            if (categoryToScroll) {
              window.scrollTo({
                top: window.scrollY + categoryToScroll.getBoundingClientRect().top - 80,
                left: 0
              }) 
            } else {
              loadCatalogue(i + 1);
            }
            
          }

          loader.classList.remove('loading')
          
        })
      })
  }

  const changeLayout = useCallback(() => {
    setIcon(prevIcon => (prevIcon + 1) % 3)
  }, [])

  const getPage = useCallback((page, finalPage, id, callback) => {
    const loader = document.querySelector('.loader')

    loader.classList.add('loading')
    
    fetch(endpoint("/api/catalogue/get/" + page))
        .then(response => response.json())
        .then(data => {
          setCollections(c => c.concat(data.collections))
          loader.classList.remove('loading')
          }).then(() =>  { 
            setPage(page)
            if(callback) 
              callback();
            if(page < finalPage) {
              getPage( page + 1, finalPage, id, callback)
            } else {
              setHash(id)
            }
          })
  }, [])

  const { ref, inView } = useInView({
    threshold: 1,
    skip: !isReady,
  });

  const toggleTag = (e) => {
    const id = e.target.dataset.id,
          allButtons = document.querySelectorAll('.catalog-wrapper .filter-tag'),
          containerSameID = document.querySelector('.collection[data-id="'+ id +'"')
    const buttonPage = parseInt(e.target.dataset.page)
    let addedContainer


    

    if(!containerSameID) {
      
      let i = currentPage + 1
        getPage(i, buttonPage, id, () => {
          addedContainer = document.querySelector('.collection[data-id="'+ id +'"')
          if(addedContainer) {
            allButtons.forEach(btn => {
              btn.classList.remove('is-active')
            })

            e.target.classList.add('is-active')
          } 
        })
    }

    else {
      allButtons.forEach(btn => {
        btn.classList.remove('is-active')
      })
  
      e.target.classList.add('is-active')

      setHash(id)
      
    }
  }

  useEffect(() => {
    
    if(inView && currentPage * 2 < pageNumber){
      getPage(currentPage + 1)

      setPage(c => c + 1)
    }
   
  }, [inView])

  const collectionGroup = collections.map((el,id) => 
      <div className="collection" data-id={string_to_slug(el.collectionName)}  key={id}>
        <h2 className="title-5">{el.collectionName}</h2>
        <div className="collection-wrapper">
          <Collection cardList={el.cards} size={icon} />
        </div>
      </div>
    )

    const tagGroup = tags.map((el,id) => 
      <li key={id}>
        <button className="filter-tag body-5" data-page={el.page} onClick={toggleTag} data-id={string_to_slug(el.name)}>{el.name}</button>
      </li>
    )

  const layoutImgSrc = [layout2, layout1, layout3][icon]
  const mainClassName = ['layout-small-cards', 'layout-default-cards', 'layout-big-cards'][icon]

  return (
     <>
      <Cover />
      <section className="catalog-wrapper">
        <header>
          <nav>
            <ul>
              <li>
                <Link onClick={() => localStorage.setItem('lastID', '')} to={`/search${path.search}`} className="search-btn">
                  <img src={searchIcon} alt="search" />
                </Link>
                <button className="layout-change" onClick={changeLayout}>
                  <img src={layoutImgSrc} alt="layout" />
                </button>
                <span className="shape" style={{background: "transparent url(./shape-filters.svg) no-repeat center / cover"}}></span>
              </li>
              <li>
                <ul className="tag-wrapper">
                  {tagGroup}
                </ul>
              </li>
            </ul>
          </nav>
        </header>
        <main className={mainClassName}>
          <img src={border} className="wrapper-border" alt="border-top"/>
          {collectionGroup}
        </main>
      </section>

      <ProductDetail hash={prodID}/>

      <span ref={ref} className="js-intersectionLoadItems"></span>
      </>
  )
}

export default Catalogue;
