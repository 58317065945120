import React from "react"
import shareIcon from './../images/vectors/share.svg'
import {shareBtn} from '../utilities/helpers'

class ShareBtn extends React.Component {
    render() {
        return(
            <button className="share-button" onClick={shareBtn}>
                <img src={shareIcon} alt="Share" />
            </button>
        )
    }
}

export default ShareBtn