import React from "react"
import {editPrice} from '../utilities/helpers'

function ListCard(props) {
    const cardProps = props.data
    const price = editPrice(cardProps.price)
    const discountPrice = editPrice(cardProps.discountPrice)

    return (
        <article  className="search-card">
            <figure>
                <picture>
                    <img src={cardProps.imageUrl.shoppingCart} alt={cardProps.productName} />
                </picture>
                <figcaption>
                    <p className="body-5">{cardProps.productName}</p>
                    <p className="footnote-2">{cardProps.brand.brandName}</p>
                    <div className="bottom-wrapper">
                        <div className="price-wrapper">
                            <span className="new-price body-2">{discountPrice}</span>
                            {cardProps.price !== 0 &&
                                <span className="old-price footnote-1">{price}</span>
                            }
                        </div>
                        <span className="add-button"></span>
                    </div>
                </figcaption>
            </figure>

            {(props.discountValue !== '' && props.discountValue !== undefined && props.discountValue !== 0 && cardProps.saleType === 'Percentage') ? 
                <span className="label body-2">{props.discountValue + '%'}</span>
                : ''
            }
        </article>
    )
}

export default ListCard