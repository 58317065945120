import {createStore} from 'redux';
import { useSelector } from 'react-redux'
import {createObject, formatPrice} from './helpers';
import {loadState, saveState} from './localstorage';

function reducer(state = [], action) {
    if(state.length === 0){
        state = {
            cart: {
                products: [],
                qtt: 0,
                totalNew: 0,
                totalOld: 0,
                totalSaved: 0
            },
            cartTemp: {
                products: []
            }
        };
    }

    let obj = (action.id !== undefined ? state[action.cart].products.find(x => x.id === action.id) : undefined);

    if (obj === undefined && action.id !== undefined) {
        obj = createObject(state, action.id, action.cart);
    }

    if(action.type === 'add'){
        obj.value = obj.value + 1;
    }
    else if(action.type === 'remove'){
        obj.value = obj.value - 1;
        if(obj.value < 1) obj.value = 1;
    }
    else if(action.type === 'removeAll'){
        obj.value = 0;
    }


    else if(action.type === 'addToCart'){
        let objCart = state['cartTemp'].products.find(x => x.id === action.id);

        //Product
        obj.value = objCart ? objCart.value : 1;
        obj.info = action.data;
        obj.total = Math.round((obj.value * obj.info.price.new) * 100) / 100;

        //Cart
        state[action.cart].qtt += obj.value;
        state[action.cart].totalNew += ((obj.info.price.new !== undefined ? obj.info.price.new : obj.info.discountPrice) * obj.value);
        state[action.cart].totalOld += ((obj.info.price.old !== undefined ? obj.info.price.old : obj.info.price) * obj.value);
        state[action.cart].totalSaved += (((obj.info.price.old ? obj.info.price.old : obj.info.price) - (obj.info.price.new ? obj.info.price.new : obj.info.discountPrice)) * obj.value);

    }

    else if(action.type === 'removeFromCart'){
        state[action.cart].products = state[action.cart].products.filter(x => x.id !== action.id);

        const card = document.querySelector('.js-cart-card[data-item="'+action.id+'"]')
        const allCards = [...document.querySelectorAll('.js-cart-card[data-item]')]
        const allCategoryCards = [...document.querySelectorAll('.category-card[data-item]')]
        const cardIndex = allCards.indexOf(card)
        allCategoryCards[cardIndex].remove()
        card.remove();

    }
    else if(action.type === 'updatePrd_Add'){
        //Product
        obj.value = obj.value + 1;
        obj.info = action.data;
        obj.total = Math.round((obj.value * obj.info.price.new) * 100) / 100;
    }
    else if(action.type === 'updatePrd_Remove'){
        //Product
        obj.value = obj.value - 1;
        obj.info = action.data;
        obj.total = Math.round((obj.value * obj.info.price.new) * 100) / 100;
    }


    //ROUND + TOTALS
    if(action.type === 'addToCart' || action.type === 'removeFromCart' || action.type === 'updatePrd_Add' || action.type === 'updatePrd_Remove') {
        let qtt = 0,
            totalNew = 0,
            totalOld = 0,
            totalSaved = 0;

        state[action.cart].products.map(el => {
            const amount = (
                el.info.unitAverageWeight > 0
                    ? el.info.unitAverageWeight / 1000
                    : el.info.soldInBulk
                        ? 0.05
                        : 1
            );

            totalNew += (el.info.price.new ? el.info.price.new : el.info.discountPrice) * el.value * amount;
            totalOld += (el.info.price.old !== 0 ? el.info.price.old : el.info.price.new) * el.value * amount;
            totalSaved += ((el.info.price.old !== 0 ? el.info.price.old : el.info.price.new) - (el.info.price.new ? el.info.price.new : el.info.discountPrice)) * el.value * amount;
            qtt += el.value;
        })

        state[action.cart].totalNew = Math.round(totalNew * 100) / 100;
        state[action.cart].totalOld = Math.round(totalOld * 100) / 100;
        state[action.cart].totalSaved = Math.round(totalSaved * 100) / 100;
        state[action.cart].qtt = qtt;
    }

    if(obj !== undefined && obj.value === 0 && action.id !== undefined){
        state[action.cart] = state[action.cart].filter(x => x.id !== action.id);
    }


    return state;
}

const persistedState = loadState();

export const store=createStore(reducer,persistedState);
store.subscribe(() => {

    /* ------
        Im really sorry about the following code.
        If you know better, please please update this.
     --- */
    const totalSaved = document.querySelector('.js-totalSaved');
    const totalOld = document.querySelector('.js-totalOld');
    const totalNew = document.querySelector('.js-totalNew');

    const state = store.getState();

    if(totalSaved !== undefined && totalSaved !== null){
        totalSaved.innerHTML = formatPrice(state.cart.totalSaved, 2) + '€';
    }
    if(totalOld !== undefined && totalOld !== null){
        totalOld.innerHTML = formatPrice(state.cart.totalOld, 2) + '€';
    }
    if(totalNew !== undefined && totalOld !== null){
        totalNew.innerHTML = formatPrice(state.cart.totalNew, 2) + '€';
    }

    saveState(state);
});

export function getStore(){
    return store.getState;
}

export function GetCart() {
    return useSelector((state) => state.cart)
}
