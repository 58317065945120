import React, { useEffect, useState } from "react"
import { endpoint } from "../utilities/helpers"
import logo from './../images/vectors/logoMP.svg'
function Cover() {

  const [image, setImage] = useState('')
  const [label, setLabel] = useState('')
  const [text, setText] = useState('')
  const [title, setTitle] = useState('')
  const [termsLink, setTermLink] = useState('')
  const [privacyLink, setPrivacyLink] = useState('')

  useEffect(() => {
      fetch(endpoint('/api/cover/get'))
      .then(response => response.json())
      .then(data => {
        setImage(data[0].imageUrl)
        setLabel(data[0].label)
        setText(data[0].text)
        setTitle(data[0].title)
        setPrivacyLink(data[0].privacyLink)
        setTermLink(data[0].termsLink)
      })
  },[])

  return(
    <section className="cover">
      <a href="/" className="main-logo"><img src={logo} alt="Logo" /></a>
      <figure>
        <picture>
          <img src={image} alt="cover"/>
        </picture>
        <figcaption>
          <div className="rte">
            <p>{label}</p>
            <p className="title-2">{title}</p>
            <p dangerouslySetInnerHTML={{__html:text}}></p>
            <div className="link-wrapper">
              <a href={termsLink} target="_blank">Termos e Condições</a>
              <a href={privacyLink} target="_blank">Política de Privacidade</a>
            </div>
          </div>
        </figcaption>
      </figure>
    </section>
  )
}

export default Cover;
