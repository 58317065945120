import React from 'react'
// import CloseIcon from '../images/vectors/close.svg'
import CheckIcon from '../images/vectors/check.svg'
//Functions
// import {removeItem} from '../utilities/helpers'

class CategoryCard extends React.Component {
    render() {

        const props = this.props

        return(
            <div className="category-card" data-item={props.info.info.id}>
                <input id={props.info.info.id} type="checkbox" />
                <label htmlFor={props.info.info.id} className="body-4"><img className="icon-check" alt="check button" src={CheckIcon} /> {props.info.info.name ? props.info.info.name : props.info.info.productName}</label>
                {/* <button className="js-removeItem remove-item" onClick={removeItem}>
                    <img alt="close button" src={CloseIcon} />
                </button> */}
            </div>
        )
    }
}

export default CategoryCard