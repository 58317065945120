import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom"
import {editPrice, calculateDays} from '../utilities/helpers'
import eyeIcon from './../images/vectors/eye.svg'
import corner from './../images/vectors/corner.svg'
import plusIcon from './../images/vectors/plus.svg'
import clockIcon from './../images/vectors/clock.svg'
import backIcon from './../images/vectors/back-arrow.svg'
import { useWindowSize } from 'usehooks-ts'

function Card(props) {
        const data = props.data
        const ref = React.createRef()
        const navigate = useNavigate()
        const {search} = useLocation()

        let days, discountPrice, price

        const [urlVideo, setUrl] = useState('')

        if(data.sale) {
            price = editPrice(data.sale.products[0].price)
            discountPrice = editPrice(data.sale.products[0].discountPrice)
            days = calculateDays(data.sale.saleEndDate)
        }

        const genericSize = props.size
        const cardSize = data.initialCardSize
        const typeClass = data.cardType === 0 ? ' single-card' : ( data.cardType === 1 ? ' multi-card' : ' content-card')
        const sizeClass = cardSize === 0 ? ' small-card' : ( cardSize === 1 ? ' medium-card' : ' big-card')
        const cardType = data.cardType
        const cardLink = cardType === 0 ? '/#' + data.sale.products[0].id : '/promocoes-folheto'

        let imgSrc, addIcon


        if(cardType === 0 )
            addIcon = plusIcon

        else
            addIcon = eyeIcon

        if(cardType === 2) {
            
            if (cardSize !== 2)
                imgSrc = data.recipe.imageUrl.medium

            else 
                imgSrc = data.recipe.imageUrl.high
        }
        
        else {
            if (cardSize === 0)
                imgSrc = data.cardImage.small

            else if ( cardSize === 1)
                imgSrc = data.cardImage.medium

            else 
                imgSrc = data.cardImage.high
        }

        let cardCondition

        // CASE FOR MULTICARD W/ SAME PRICE PRODUCTS
        if(cardType === 1 && data.samePriceProducts && !data.isPrecoMini)
            cardCondition = 1
        
        // CASE FOR MULTICARD W/ DIFFERENT PRICE PRODUCTS
        else if(cardType === 1 && !data.samePriceProducts && !data.isPrecoMini)
            cardCondition = 2

        // CASE FOR SINGLECARD W/ PREÇO MINI
        else if(cardType === 0 && data.isPrecoMini)
            cardCondition = 3

        // CASE FOR MULTICARD W/ PREÇO MINI
        else if(cardType === 1 && data.isPrecoMini)
            cardCondition = 4

        // CASE FOR LEVA X PAGA Y
        else if(data.sale && data.sale.productsTaken > 0)
            cardCondition = 5

        // DEFAULT SINGLECARD W/O CONDITIONS
        else
            cardCondition = 0


        const hasIntersectionObserver = useRef(false)
            
        useEffect(() => {
            const card = ref.current

            if (card.classList.contains('content-card')) {
                const video = card.querySelector('video')
                const duration = card.querySelector('.video-duration')

                video.addEventListener('loadeddata', () => {
                    const minutes = parseInt(video.duration / 60, 10)
                    const seconds = parseInt(video.duration % 60)

                    duration.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                })

                video.addEventListener('timeupdate', () => {
                    const currentTime = video.duration - video.currentTime
                    const minutes = parseInt(currentTime / 60, 10)
                    const seconds = parseInt(currentTime % 60)

                    duration.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                })

                if (!hasIntersectionObserver.current) {
                    const observer = new IntersectionObserver(entries => {
                        for (const entry of entries) {
                            if (entry.isIntersecting) {
    
                                video.play()
    
                                observer.disconnect()
                            }
                        }
                    }, {
                        threshold: 0
                    })
                    
                    observer.observe(ref.current)
                    hasIntersectionObserver.current = true
                }
            }
        }, [ref, hasIntersectionObserver])

        const defaultColors = ['Yellow','Red','Green','Blue','Green Water','Purple','Easter Blue', 'Easter Yellow']

        return(
            <>
            { cardType === 0
            ?
                <div 
                    ref={ref}
                    to={cardType !== 2 ? cardLink : ''}
                    state={cardType !== 2 ? data.sale : data.recipe}
                    id={'card-id-' + (cardType !== 2 ? data.sale.id : data.recipe.id)}
                    className={
                        'catalog-card'
                        + typeClass
                        + sizeClass
                        + (data.isClubeMinipreco ? ' clube-minipreco' : '')
                        + (data.isPrecoMini ? ' preco-mini' : '')
                        + (data.samePriceProducts ? ' same-price' : '')
                    }
                    style={{
                        backgroundColor:data.backgroundColor,
                        color: defaultColors.includes(data.cardColorTheme) ? '' : data.cardColorTheme 
                    }}
                    onClick={() => {
                            window.history.pushState('product detail','','/promocao-produto-' + data.sale.products[0].id + window.location.search)
                            window.dispatchEvent(new Event('popstate'));
                        
                            const mainNav = document.querySelector('.main-nav'),
                            body = document.querySelector('body');

                            localStorage.setItem('lastID', cardType !== 2 ? data.sale.id : data.recipe.id);    

                            mainNav.classList.add('going-down')
                            body.classList.add('no-scroll')

                            // Remove Share when native share doesn't exist
                            if (!navigator.share) {
                                const share = document.querySelector('.share-button')
                                if (share)
                                share.remove();
                            }
                    }}
                >
                    { ((cardSize !== 0 && genericSize !== 0) && data.cardType !== 2) &&
                        <>
                            { (cardCondition !== 0 && cardCondition !== 1 && cardCondition !== 3 && cardCondition !== 5 && data.brandLogo) &&
                                <img src={data.brandLogo} className='partner-logo' alt='partner' />
                            }
                            
                            { (data.sealImageUrl) && 
                                <img src={data.sealImageUrl} className='seal-logo' alt='seal' />
                            }
                        </>
                    }
                    { data.cardType !== 2 &&
                        <>
                            <div className={'tag-wrapper' + (cardCondition !== 0 ? ' tag-wrapper-' + cardCondition : '')}>
                                { cardCondition === 0 &&
                                    <>
                                        {(data.sale && data.sale.saleDescriptionLabel) &&
                                            <div className={"small-tag body-1"}>
                                                {
                                                    data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                    ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                    : data.sale.saleDescriptionLabel
                                                }
                                                {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                    <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                                }
                                            </div>
                                        }
                                        <div className={"big-tag " + genericSize + cardSize}>
                                            { (data.labelAbovePrice && isNaN(parseInt(data.labelAbovePrice)))
                                                ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                                :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                    <span className="old-price footnote-5">{data.labelAbovePrice}</span>
                                            }
                                            <span className="upper-content title-4 new-price">{discountPrice}</span>
                                            { data.labelUnderPrice &&
                                                <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                            }
                                        </div>
                                    </>
                                }
                                { cardCondition === 1 &&
                                    <>
                                        { data.sale.saleDescriptionLabel
                                            ?
                                                <>
                                                    { genericSize !== 0 &&
                                                        <div className="big-tag">
                                                            
                                                            { (data.labelAbovePrice && isNaN(parseInt(data.labelAbovePrice)))
                                                                ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                                                :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                                    <span className="old-price footnote-5">{data.labelAbovePrice}</span>
                                                            }
                                                            <span className="upper-content title-4 new-price">{discountPrice}</span>
                                                            { data.labelUnderPrice &&
                                                                <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                                            }
                                                        </div>
                                                    }
                                                    <div className='bottom-tag'>
                                                        { genericSize !== 0 &&
                                                            <div className='sale-label'>
                                                                {
                                                                    data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                                    ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                                    : data.sale.saleDescriptionLabel
                                                                }
                                                                {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                                    <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                                                }
                                                            </div>
                                                        }
                                                        <div
                                                            className='card-name'
                                                            style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}
                                                        >{data.cardName}</div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div className="big-tag">
                                                        { data.labelAbovePrice
                                                            ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                                            :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                                <span className="old-price footnote-5">{price}</span>
                                                        }
                                                        <span className="upper-content title-4 new-price">{discountPrice}</span>
                                                        { data.labelUnderPrice &&
                                                            <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                                        }
                                                        <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                                    </div>
                                                </>
                                        } 
                                    </>
                                }
                                { cardCondition === 2 &&
                                    <>
                                        { data.sale.saleDescriptionLabel &&
                                            <div className="top-tag small-tag">
                                                 (data.labelAbovePrice || isNaN(parseInt(data.labelAbovePrice)))
                                                    ?
                                                    {
                                                        <span className='upper-label'>{data.labelAbovePrice}</span>
                                                    }
                                                    :
                                                    {
                                                        <span className='old-price footnote-5'>{data.labelAbovePrice}</span>
                                                    }
                                                
                                                <span className="upper-content title-4 new-price">
                                                    {
                                                        data.sale.saleDescriptionLabel
                                                        && data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                        ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                        : data.sale.saleDescriptionLabel
                                                    }
                                                    {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                        <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                                    }
                                                </span>
                                            </div>
                                        }
                                        <div className="big-tag">
                                            <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                        </div>
                                    </>
                                }

                                { cardCondition === 3 &&
                                    <>
                                        { data.sale &&
                                            <div className={"small-tag body-1"}>Preço Mini</div>
                                        }
                                        <div className="big-tag">
                                            { data.labelAbovePrice &&
                                                <span className='upper-label'>{data.labelAbovePrice}</span>
                                            }
                                            <span className="upper-content title-4 new-price">{discountPrice}</span>
                                            { data.labelUnderPrice &&
                                                <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                            }
                                        </div>
                                    </>
                                }

                                { cardCondition === 4 &&
                                    <>
                                        <div className="big-tag">
                                            <div className="upper-label body-1">Preço Mini</div>
                                            <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                        </div>
                                    </>
                                }
                                { cardCondition === 5 &&
                                    <>
                                        <div className="small-tag body-1">
                                            {
                                                'Leve ' + data.sale.productsTaken + (data.sale.productsSold > 0 ? '' : 'por')
                                            }
                                            {   data.sale.productsSold > 0 &&
                                                <>
                                                    <br/>
                                                    { 'pague ' + data.sale.productsSold}
                                                </>
                                            }
                                        </div>
                                        <div className="big-tag">
                                            { data.labelAbovePrice
                                                ?  <span className='upper-label'>{data.sale.LabelOverPrice}</span>
                                                :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0)))&&
                                                    <span className="old-price footnote-5">{discountPrice !== -1 ? '' : price}</span>
                                            }
                                            <span className="upper-content title-4 new-price">{discountPrice !== -1 ? price : discountPrice}</span>
                                            { data.labelUnderPrice &&
                                                <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                    { data.cardType === 2 &&
                        <span className='video-duration body-5'></span>
                    }

                    { data.cardType === 2
                        ? <video className="product-img" muted playsInline loop>
                            <source src={data.recipe.videoUrl}></source>
                        </video>
                        : <img src={imgSrc} className="product-img" alt="nutella" />
                    }
                    { (cardCondition !== 1 && cardCondition !== 2 && cardCondition !== 4) &&
                        <div className="card-info-icon-wrapper">
                            <div className="card-info">
                                <p className="footnote-4">{data.cardName}</p>
                                { (cardType === 0 && (genericSize === 2 || (genericSize === 1 && cardSize !== 0))) &&
                                    <p className='title-6'>{data.sale.products[0].brand.brandName}</p>
                                }
                                { (cardSize !== 0 || genericSize !== 0) &&
                                    <>
                                    { (data.cardType === 0 && (cardCondition === 3 || cardCondition === 0 ) && (genericSize === 2 || cardSize !== 0 && genericSize === 1)) &&
                                        <p className='quantity-info'>
                                            {data.sale.products[0].quantityLabel && 
                                            <>
                                                <span className='weight footnote-4'>{data.sale.products[0].quantityLabel}</span>
                                                <span className='price-weight footnote-4'>{data.sale.products[0].pricePerQuantityLabel}</span>
                                            </>
                                            }
                                        </p>
                                    }
                                    </>
                                }
                                { data.cardType === 2 &&
                                    <p className="footnote-5">{data.recipe.author}</p>
                                }
                            </div>   
                            <img src={addIcon} alt="see more" className='see-more-button' />
                        </div>
                    }
                    
                    { /* data.cardType !== 2 &&
                        <div className="duration">
                            <img src={corner} className="right-corner" alt="right corner"/>
                            <img src={corner} className="left-corner" alt="left corner"/>
                            <img src={clockIcon} alt="clock" />
                            <span className="footnote-1">{ (cardSize === 0 || genericSize === 0 ? '' : 'Termina em ' ) + days + ' Dias'} </span>
                        </div>
                    */ }
                    { (cardCondition === 1 || cardCondition === 2 || cardCondition === 4) &&
                        <img src={addIcon} alt="see more" className='see-more-button' />  
                    }
                </div>
            :
                <Link 
                ref={ref}
                to={(cardType !== 2 ? cardLink : '') + search}
                state={cardType !== 2 ? data.sale : data.recipe}
                id={'card-id-' + (cardType !== 2 ? data.sale.id : data.recipe.id)}
                className={
                    'catalog-card'
                    + typeClass
                    + sizeClass
                    + (data.isClubeMinipreco ? ' clube-minipreco' : '')
                    + (data.isPrecoMini ? ' preco-mini' : '')
                    + (data.samePriceProducts ? ' same-price' : '')
                }
                style={{
                    backgroundColor:data.backgroundColor,
                    color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme 
                }}
                onClick={() => {
                    if (cardType !== 2) {

                        const mainNav = document.querySelector('.main-nav'),
                        body = document.querySelector('body');

                        localStorage.setItem('lastID', cardType !== 2 ? data.sale.id : data.recipe.id);    

                        mainNav.classList.add('going-down')

                        // Remove Share when native share doesn't exist
                        if (!navigator.share) {
                            const share = document.querySelector('.share-button')
                            if (share)
                            share.remove();
                        }
                    }
                    else {
                        document.querySelector('.catalog-wrapper header').style.opacity = 0
                        document.querySelector('.catalog-wrapper header').style.pointerEvents = 'none'
                        document.body.classList.add('no-scroll')
                        document.querySelector('.main-nav').classList.add('going-down')
                        setUrl(data.recipe.videoUrl)
                    }
                }}
            >
                { ((cardSize !== 0 && genericSize !== 0) && data.cardType !== 2) &&
                    <>
                        { (cardCondition !== 0 && cardCondition !== 1 && cardCondition !== 3 && cardCondition !== 5 && data.brandLogo) &&
                            <img src={data.brandLogo} className='partner-logo' alt='partner' />
                        }
                        
                        { (data.sealImageUrl) && 
                            <img src={data.sealImageUrl} className='seal-logo' alt='seal' />
                        }
                    </>
                }
                { data.cardType !== 2 &&
                    <>
                        <div className={'tag-wrapper' + (cardCondition !== 0 ? ' tag-wrapper-' + cardCondition : '')}>
                            { cardCondition === 0 &&
                                <>
                                    {(data.sale && data.sale.saleDescriptionLabel) &&
                                        <div className={"small-tag body-1"}>
                                            {
                                                data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                : data.sale.saleDescriptionLabel
                                            }
                                            {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                            }
                                        </div>
                                    }
                                    <div className={"big-tag " + genericSize + cardSize}>
                                        { data.labelAbovePrice
                                            ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                            :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                <span className="old-price footnote-5">{price}</span>
                                        }
                                        <span className="upper-content title-4 new-price">{discountPrice}</span>
                                        { data.labelUnderPrice &&
                                            <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                        }
                                    </div>
                                </>
                            }
                            { cardCondition === 1 &&
                                <>
                                    { data.sale.saleDescriptionLabel
                                        ?
                                            <>
                                                { genericSize !== 0 &&
                                                    <div className="big-tag">
                                                        { data.labelAbovePrice
                                                            ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                                            :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                                <span className="old-price footnote-5">{price}</span>
                                                        }
                                                        <span className="upper-content title-4 new-price">{discountPrice}</span>
                                                        { data.labelUnderPrice &&
                                                            <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                                        }
                                                    </div>
                                                }
                                                <div className='bottom-tag'>
                                                    { genericSize !== 0 &&
                                                        <div className='sale-label'>
                                                            {
                                                                data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                                ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                                : data.sale.saleDescriptionLabel
                                                            }
                                                            {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                                <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                                            }
                                                        </div>
                                                    }
                                                    <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className="big-tag">
                                                    { data.labelAbovePrice
                                                        ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                                        :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0))) &&
                                                            <span className="old-price footnote-5">{price}</span>
                                                    }
                                                    <span className="upper-content title-4 new-price">{discountPrice}</span>
                                                    { data.labelUnderPrice &&
                                                        <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                                    }
                                                    <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                                </div>
                                            </>
                                    } 
                                </>
                            }
                            { cardCondition === 2 &&
                                <>
                                    { data.sale.saleDescriptionLabel &&
                                        <div className="top-tag small-tag">
                                            
                                            {/* {(data.labelAbovePrice && isNaN(parseInt(data.labelAbovePrice)))
                                                    ?
                                            
                                                        <span className='upper-label'>{data.labelAbovePrice}</span>
                                            
                                                    :
                                            
                                                        <span className='old-price footnote-5'>{data.labelAbovePrice}</span>
                                            } */}
                                                
                                            <span className="upper-content title-4 new-price">
                                                {
                                                    data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr')
                                                    ? data.sale.saleDescriptionLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                                    : data.sale.saleDescriptionLabel
                                                }
                                                {data.sale.saleDescriptionLabel.toLowerCase().endsWith('sobre pvpr') &&
                                                    <span className="pvpr">{data.sale.saleDescriptionLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                                                }
                                            </span>
                                        </div>
                                    }
                                    <div className="big-tag">
                                        <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                    </div>
                                </>
                            }

                            { cardCondition === 3 &&
                                <>
                                    { data.sale &&
                                        <div className={"small-tag body-1"}>Preço Mini</div>
                                    }
                                    <div className="big-tag">
                                        { data.labelAbovePrice
                                            ?  <span className='upper-label'>{data.labelAbovePrice}</span>
                                            :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0)))&&
                                                <span className="old-price footnote-5">{price}</span>
                                        }
                                        <span className="upper-content title-4 new-price">{discountPrice}</span>
                                        { data.labelUnderPrice &&
                                            <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                        }
                                    </div>
                                </>
                            }

                            { cardCondition === 4 &&
                                <>
                                    <div className="big-tag">
                                        <div className="upper-label body-1">Preço Mini</div>
                                        <div className='card-name' style={{color: defaultColors.includes(data.cardColorTheme)? '' : data.cardColorTheme}}>{data.cardName}</div>
                                    </div>
                                </>
                            }
                            { cardCondition === 5 &&
                                <>
                                    <div className="small-tag body-1">
                                        {
                                            'Leve ' + data.sale.productsTaken + (data.sale.productsSold > 0 ? '' : 'por')
                                        }
                                        {   data.sale.productsSold > 0 &&
                                            <>
                                                <br/>
                                                { 'pague ' + data.sale.productsSold}
                                            </>
                                        }
                                    </div>
                                    <div className="big-tag">
                                        { data.labelAbovePrice
                                            ?  <span className='upper-label'>{data.sale.LabelOverPrice}</span>
                                            :  (data.sale.products[0].price !== data.sale.products[0].discountPrice && (genericSize !== 0 && !(genericSize === 1 && cardSize === 0)))&&
                                                <span className="old-price footnote-5">{discountPrice !== -1 ? '' : price}</span>
                                        }
                                        <span className="upper-content title-4 new-price">{discountPrice !== -1 ? price : discountPrice}</span>
                                        { data.labelUnderPrice &&
                                            <span className="bottom-content footnote-5">{data.labelUnderPrice}</span>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                { data.cardType === 2 &&
                    <span className='video-duration body-5'></span>
                }

                { data.cardType === 2
                    ? <video className="product-img" muted playsInline loop>
                        <source src={data.recipe.videoUrl}></source>
                    </video>
                    : <img src={imgSrc} className="product-img" alt="nutella" />
                }
                { (cardCondition !== 1 && cardCondition !== 2 && cardCondition !== 4) &&
                    <div className="card-info-icon-wrapper">
                        <div className="card-info">
                            <p className="footnote-4">{data.cardName}</p>
                            { (cardType === 0 && (genericSize === 2 || (genericSize === 1 && cardSize !== 0))) &&
                                <p className='title-6'>{data.sale.products[0].brand.brandName}</p>
                            }
                            { (cardSize !== 0 || genericSize !== 0) &&
                                <>
                                { (data.cardType === 0 && (cardCondition === 3 || cardCondition === 0 ) && (genericSize === 2 || cardSize !== 0 && genericSize === 1)) &&
                                    <p className='quantity-info'>
                                        {data.sale.products[0].quantityLabel && 
                                        <>
                                            <span className='weight footnote-4'>{data.sale.products[0].quantityLabel}</span>
                                            <span className='price-weight footnote-4'>{data.sale.products[0].pricePerQuantityLabel}</span>
                                        </>
                                        }
                                    </p>
                                }
                                </>
                            }
                            { data.cardType === 2 &&
                                <p className="footnote-5">{data.recipe.author}</p>
                            }
                        </div>   
                        <img src={addIcon} alt="see more" className='see-more-button' />
                    </div>
                }
                
                { /* data.cardType !== 2 &&
                    <div className="duration">
                        <img src={corner} className="right-corner" alt="right corner"/>
                        <img src={corner} className="left-corner" alt="left corner"/>
                        <img src={clockIcon} alt="clock" />
                        <span className="footnote-1">{ (cardSize === 0 || genericSize === 0 ? '' : 'Termina em ' ) + days + ' Dias'} </span>
                    </div>
                */ }
                { (cardCondition === 1 || cardCondition === 2 || cardCondition === 4) &&
                    <img src={addIcon} alt="see more" className='see-more-button' />  
                }
                </Link>
            }
            
            {urlVideo !== '' &&
                <div className='video-modal'>
                    <video className="product-img" autoPlay playsInline loop>
                        <source src={urlVideo}></source>
                    </video>
                    <button className='modal-back' onClick={() => {
                        document.querySelector('.catalog-wrapper header').style.opacity = 1
                        document.querySelector('.catalog-wrapper header').style.pointerEvents = 'auto'
                        document.body.classList.remove('no-scroll')
                        setUrl('')

                    }}>
                        <img src={backIcon} alt=""/>
                    </button>

                    <div className='video-info'>
                        <div className='video-content'>
                            <p className="footnote-4">{data.cardName}</p>
                            <p className="footnote-5">{data.recipe.author}</p>
                        </div>
                        { data.recipe.products.length > 0 &&
                            <img src={addIcon} onClick={() => {
                                navigate('/promocoes-folheto' + window.location.search, {state:data.recipe})
                                document.body.classList.remove('no-scroll')
                            }} 
                            alt="see more" className='see-more-button' />
                        }
                    </div>
                </div>
            }
            
            </>
        )
}

export default Card
