import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom"
import CartCard from "../components/cartCard";
import Tabs from "../components/tabs";
import CategoryCard from "../components/categoryCard";
import shoppingCard from './../images/shoppingCard.png'
import {endpoint, formatPrice} from "../utilities/helpers";
import {store} from "../utilities/store";


function ShoppingCart() {
    const state = store.getState()
    const [cards, setCards] = useState(state.cart.products)
    const [prevY, setPrevY] = useState(0)


    useEffect(() => {
        document.addEventListener('scroll', (e) => {
            const distanceTraveled = window.pageYOffset
            const btnFixed = document.querySelector('.js-btnFixed')

            if (btnFixed) {
                btnFixed.classList.toggle('going-down', distanceTraveled > prevY)
                setPrevY(distanceTraveled)
                document.querySelector('.main-nav').classList.toggle('going-down', (distanceTraveled > prevY))
                setPrevY(distanceTraveled)
            }
        })


    }, [])

    const sendCart = () => {
        const products = state.cart.products

        const cart = products.map(el => ({
            productId: el.id,
            quantity: el.value,
            soldInBulk: (el.info.soldInBulk ? el.info.soldInBulk : false)
        }))
        fetch(endpoint('/api/shoppingcart/submit'),
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cart)
            }
        ).then(response => response.json())
        .then(result => { 
            window.location.replace(result)
        })
    }


    let cartCardList = []
    let categoryCardsList = []
        

    if (cards) {
        cartCardList = cards.map((el, id) => 
            <CartCard info={el} key={id}/>
        )

        categoryCardsList = cards.map((el, id) =>
            <CategoryCard info={el} key={id}/>
        )
    }
    
    const {search} = useLocation()


    return (
        <section className="shopping-cart-wrapper">
            {(cards && cards.length > 0)
                ?
                <>
                    <Tabs firstTab="Pedido" secondTab="Lista de Compras"/>
                    <div className="content is-active" data-id="list">
                        {cartCardList}

                        <div className="info-wrapper">
                            <div>
                                <span className="body-5">Você poupou</span>
                                <span
                                    className="body-4 js-totalSaved">{formatPrice(state.cart.totalSaved, 2) + '€'}</span>
                            </div>
                        </div>

                        <div className="total-wrapper">
                            <span className="body-4">Total</span>
                            <div>
                                <span className="body-4 js-totalOld">{formatPrice(state.cart.totalOld, 2) + '€'}</span>
                                <span className="title-5 js-totalNew">{formatPrice(state.cart.totalNew, 2) + '€'}</span>
                            </div>
                        </div>
                        <button onClick={sendCart} className="btn btn-red btn-full body-4 js-btnFixed">
                            Encomendar Online
                        </button>
                    </div>
                    <div className="content" data-id="description">
                        {categoryCardsList}
                    </div>
                </>
                :
                <>
                    <div className="shopping-no-results">
                        <picture className="picture-cart">
                            <img alt="cart icon" src={shoppingCard} width="151" height="131"/>
                        </picture>
                        <h3 className="title-6">O seu carrinho está vazio!</h3>
                        <Link to={`/${search}`} className="link-blue body-4">
                            Ver ofertas
                        </Link>
                    </div>
                </>
            }
        </section>
    )
}

export default ShoppingCart;
