import React, { useState } from 'react'
import CloseIcon from '../images/vectors/close.svg'
import QuantityInput from './quantityInput'
//Functions
import {getCartQtt, formatPrice, getInputQtt} from '../utilities/helpers'
import {store} from "../utilities/store";


const CartCard = (props) => {

    const cardProps = props.info
    const [multiplier, setMultiplier] = useState(1)

    const getChildValue = (value) => {
        if (value)
            setMultiplier(multiplier + 1)
        else
            setMultiplier(multiplier - 1)
    }

    function removeProd(){
        store.dispatch({type: 'removeFromCart', cart: 'cart', id: cardProps.info.id})
        
        document.querySelector('.cart-count').innerHTML = getCartQtt('cart')
    }

    let qtt = getInputQtt(cardProps.info.id, 'cart');
    let amount = (
        cardProps.info.unitAverageWeight > 0
            ? cardProps.info.unitAverageWeight / 1000
            : cardProps.info.soldInBulk
                ? 0.05
                : 1
    );

    const oldPrice = formatPrice((cardProps.info.price.old ? cardProps.info.price.old : cardProps.info.price) * qtt * amount, 2)

    const newPrice = formatPrice((cardProps.info.price.new ? cardProps.info.price.new : cardProps.info.discountPrice) * qtt * amount, 2)

    return (
        <article className="cart-card js-cart-card" data-item={cardProps.info.id}>
            <figure>
                <picture>
                    <img alt="product" src={cardProps.info.img ? cardProps.info.img : cardProps.info.imageUrl.shoppingCart} />
                </picture>
                <figcaption>
                    <div className="caption-header">
                        <p className="product-name">{cardProps.info.name}</p>
                        <button className="js-removeItem remove-item" onClick={removeProd}>
                            <img alt="close button" src={CloseIcon} />
                        </button>
                    </div>
                    <p className="product-brand">{cardProps.info.brand.brandName}</p>
                    <div className="caption-footer">
                        <div className="price-wrapper">
                            <span className="new-price">{newPrice !== '-1.00' ? newPrice : oldPrice}</span>
                            {(newPrice !== '-1.00' && !isNaN(oldPrice)) &&
                                <span className="old-price">{oldPrice}</span>
                            }
                        </div>
                        <QuantityInput bulk={cardProps.info.soldInBulk} product={cardProps.info} valueFunc={getChildValue} cart={true} />
                    </div>
                </figcaption>
            </figure>
        </article>
    )
}

export default CartCard
