import React from "react"
import { endpoint } from "../utilities/helpers"
import { Link } from "react-router-dom"
import homeIcon from '../images/vectors/home.svg'
import discoverIcon from '../images/vectors/discover.svg'
import cartIcon from '../images/vectors/cart.svg'
import {getCartQtt} from "../utilities/helpers"


class MainNav extends React.Component {

    constructor() {
        super()
        this.state = {
            prevY: 0,
            allCards: null,
            totalCards: 0
        }
    }

    componentDidMount() {

        fetch(endpoint("api/catalogue/getcontent/1"))
        .then(response => response.json())
        .then(data => {
            this.setState(() => ({totalCards: data.totalCards}));
            console.log(data.totalCards, this.state.totalCards);
        })
        
        document.addEventListener('scroll', (e) => {

            const distanceTraveled = window.pageYOffset
            const nav = document.querySelector('.main-nav')
            nav.classList.toggle('going-down',distanceTraveled > this.state.prevY)
            
            this.setState(() => {
                return {
                    prevY: distanceTraveled
                }
            })

        })
    }


   

    render() {

        const navLinks = [
            {
                url: '/',
                label: 'Folheto',
                img: homeIcon
            },
            {
                url: '/descobre',
                label: 'Inspira-te',
                img: discoverIcon
            },
            {
                url: '/shopping-cart',
                label: 'Cesto',
                img: cartIcon,
                count: true
            }
        ]

        

        
        

        let qtt = getCartQtt('cart');

        const preview = new URLSearchParams(window.location.search).get('preview');

        const links = navLinks.map((link,id) =>
            ((link.url === '/descobre' &&  this.state.totalCards > 0) || (link.url !== '/descobre')) &&
            <li key={id} onClick={() => localStorage.setItem('lastID', '')}>
                <Link to={link.url + (preview ? '?preview=' + preview : '')}>
                    <img alt={link.label} src={link.img}/>
                    {link.count &&
                        <span className="js-cartCount cart-count">{qtt}</span>
                    }
                    <span>{link.label}</span>
                </Link>
            </li>
        )
    
        return (
            <nav className="main-nav showing">
                <ul>
                    {links}
                </ul>
            </nav>
        );
    }
}

export default MainNav
