import React from 'react'

class Tabs extends React.Component {
    toggleTab(e) {
        const id = e.target.dataset.id
        const allButtons = document.querySelectorAll('.tab-wrapper button')
        const allTabs = document.querySelectorAll('.content')

        allButtons.forEach(btn => {
            btn.classList.remove('is-active')
        })

        e.target.classList.add('is-active')

        allTabs.forEach(tab => {
            const tabId = tab.dataset.id
            tab.classList.toggle('is-active',tabId === id)
        })
    }

    render() {
        return (
            <div className="tab-wrapper">
                <ul>
                    <li>
                        <button data-id="list" className="body-4 is-active" onClick={this.toggleTab}>{this.props.firstTab}</button>
                    </li>
                    <li>
                        <button data-id="description"  className="body-4" onClick={this.toggleTab}>{this.props.secondTab}</button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Tabs