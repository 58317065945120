import React from 'react'
import Card from './card'

function Collection (props){
        const allCards = props.cardList.map((card,id) =>
        
            <Card data={card} size={props.size} key={id} />
        )
        return allCards   
}

export default Collection