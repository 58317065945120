// -- Edit Price --
import {store} from "./store";

export function editPrice(price) {
    // transform to string
    const prices = price.toString().split('.')

    const smallPrice = !prices[1] ? '00' : (prices[1].length < 2 ? prices[1] + '0' : prices[1])
    return (
        <>
            <span className="big-price">{prices[0]},</span><span className="small-price">{smallPrice}</span>
        </>
    )
}

// -- Time Left --
export function calculateDays(date) {
    const removeTime = date.split(' ')
    const promoDate = new Date(removeTime[0])
    const today = new Date()
    const factor = 1000 * 60 * 60 * 24
    const dayDiff = Math.ceil(Math.abs(promoDate - today) / factor)

    return dayDiff
}
// -- Remove Item --
export function removeItem(e) {
    const el = e.currentTarget,
          parent = el.closest('[data-item]'),
          allItemsSameData = document.querySelectorAll('[data-item="' + parent.dataset.item + '"')
    
    removeProduct(parent.dataset.item)
    allItemsSameData.forEach(item => {
        item.remove()
    })
}

// -- Share Geral --
export function shareBtn() {
    if (navigator.share) {
        const url = window.location.href

        navigator.share({
            title: 'Title',
            url: url
        })
        .catch(console.error);
    } 
}

// -- Add to cart --
export function addProduct(product,qty) {
    const products = localStorage.getItem('productList') ? JSON.parse(localStorage.getItem('productList')) : []
    if(products.length) {
        let added = false
        products.forEach(el => {
            if (product.id === el.data.id && !added){
                added = true
                if (qty === -1 && el.qty === 1) {
                    products.pop(el)
                }
                el.qty += qty
                localStorage.setItem('productList', JSON.stringify(products))
            }
            else if(!added) {
                localStorage.setItem('productList', JSON.stringify(products.concat({qty: qty,data:product})))
                //document.querySelector('.cart-count').innerHTML = products.length + 1
            }
        })
    }
    else {
        localStorage.productList =  JSON.stringify([{qty: 1,data:product}])
        //document.querySelector('.cart-count').innerHTML = products.length + 1
    }

    
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

export function removeProduct(id) {
    const products = localStorage.getItem('productList') ? JSON.parse(localStorage.getItem('productList')) : [] 
    products.forEach((el, i) => {
        if (el.data.id === id){
            products.splice(i,1)
        }
    })
    localStorage.setItem('productList', JSON.stringify(products))
}

export function getProductsNumber() {
    return localStorage.getItem('productList') ? JSON.parse(localStorage.getItem('productList')).length : 0
}

export function getDomain() {
    return window.location.port !== ''
        && window.location.port >= 3000
        && window.location.port < 4000
        ? process.env.REACT_APP_DEV_DOMAIN
        : window.location.origin;
}


export function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export function endpoint(path = '', searchParams = {}, usePreview = true) {
    const endpoint = new URL(path, getDomain());
    const locationParams = new URLSearchParams(window.location.search);

    if (usePreview && locationParams.has('preview')) {
        searchParams.preview = locationParams.get('preview');
    }

    for (const [key, value] of Object.entries(searchParams)) {
        endpoint.searchParams.append(key, value);
    }

    return endpoint;
}

export function createObject(state = [], id, cart){
    let obj = {
        id: id,
        value: 1,
        data: '',
        total: 0
    };
    state[cart].products.push(obj);

    return obj;
}

export function getInputQtt(id, cart){
    const state = store.getState();
    if(state!== undefined){
        let obj = (id !== undefined ? state[cart].products.find(x => x.id === id) : undefined);

        if(obj === undefined && id !== undefined){
            createObject(state, id, cart);
        }
        if(obj !== undefined){
            return obj.value;
        }
        else {
            return (cart === 'cart' ? 0 : 1);
        }
    }
    else {
        return (cart === 'cart' ? 0 : 1);
    }
}

export function getCartQtt(cart){
    const state = store.getState();
    return state[cart].products.length;
}

export function formatPrice(value, decimals = 2){
    return Number(Math.round(parseFloat(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
}
