import React from 'react'
import SearchCard from "../components/searchCard"

class searchCollection extends React.Component {
    render() {
        const props = this.props
        const allCards = props.cardList.products.map((card,id) =>
            <SearchCard data={card} size={props.size} key={id} />
        )
        return allCards
    }
}

export default searchCollection