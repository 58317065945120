import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom"
// Icons
import timeIcon from './../images/vectors/clock.svg'
import arrowUp from './../images/vectors/arrow-up.svg'
// --
import QuantityInput from '../components/quantityInput'
import {editPrice, calculateDays, endpoint, getInputQtt} from '../utilities/helpers'
import ShareBtn from "../components/share-btn";
import {useTimeout} from 'usehooks-ts'
import {store} from '../utilities/store'

const ProductDetail = (props) => {

    const location = useLocation()

    setTimeout(() => document.querySelector('.main-nav').classList.add('going-down'),10)

    let hash = props.hash

    const [productInfo, setInfo] = useState([])
    const [modalHidden, setVisible] = useState(false)
    const [requested, setRequested] = useState(false)

    useEffect(() => {
        if (hash) {
            setRequested(true)
            fetch(endpoint("/api/product/" + hash))
                .then(response => response.json())
                .then(data => {
                    setInfo(data)
                })
        }
    }, [props.hash])

    const navigate = useNavigate()

    const modalShow = () => setVisible(true)

    useTimeout(modalShow, 250)

    const productDetailInfo = {
        id: productInfo.id,
        name: productInfo.productName ? productInfo.productName : '',
        brand: productInfo.brand ? productInfo.brand.brandName : '',
        weight: productInfo.quantityLabel ? productInfo.quantityLabel : 0,
        priceWeight: productInfo.pricePerQuantityLabel ? productInfo.pricePerQuantityLabel : 0,
        price: {
            old: productInfo.price ? productInfo.price : 0.00,
            new: productInfo.discountPrice ? productInfo.discountPrice : 0.00
        },
        discountLabel: productInfo.saleDescriptionLabel ? productInfo.saleDescriptionLabel : '',
        img: productInfo.imageUrl ? productInfo.imageUrl.detail : '',
        time: productInfo.saleEndDate ? 'Termina em ' + calculateDays(productInfo.saleEndDate) + ' dias': '',
        quantity: 1,
        backgroundColor: productInfo.productDetailBackgroundColor,
        backgroundColorValue: productInfo.productDetailBackgroundColorValue,
        saleType: productInfo.saleType,
        soldInBulk: productInfo.soldInBulk,
        unitAverageWeight: productInfo.unitAverageWeight
    }
    const currentQtt = getInputQtt(productDetailInfo.id, 'cartTemp')
    const [multiplier, setMultiplier] = useState(currentQtt)

    useEffect(()=> {
        setMultiplier(currentQtt)
    }, [currentQtt])
    
    const getChildValue = (value) => {
        if (value)
            setMultiplier(multiplier + 1)
        else
            setMultiplier(multiplier - 1)
    }

    // Open Modal
    if (modalHidden) {
        const modal = document.querySelector('.js-productDetailModal');

        modal.classList.add('is-visible');
    }

    const goBack = () => {

        if (!document.querySelector('.product-list-wrapper') && !document.querySelector('.search-wrapper')) {
            setRequested(false)
            
            

            if (location.pathname !== '/')
                navigate('/' + window.location.search)
            else {
                document.querySelector('.product-detail-wrapper').classList.remove('show')
            }
        }

        if (document.querySelector('.search-wrapper')) {
            navigate(-1)
        }

        
        const body = document.querySelector('body'),
            mainNav = document.querySelector('.main-nav');

        body.classList.remove('no-scroll')

        mainNav.classList.remove('going-down')
        document.querySelector('.product-detail-wrapper').classList.remove('show')
    }

    const multipliedPrice = (referencePrice) => {
        const referenceAmount = (
            productDetailInfo.unitAverageWeight > 0
                ? productDetailInfo.unitAverageWeight / 1000
                : productInfo.soldInBulk
                    ? 0.05
                    : 1
        )

        return editPrice((Math.round(referencePrice * multiplier * referenceAmount * 100) / 100))
    }

    let pId = productDetailInfo.id

    const addItem = () => {
        store.dispatch({type: 'addToCart', cart: 'cart', id: pId, data: productDetailInfo})
        document.querySelector('.product-detail-wrapper').classList.remove('show')
        document.body.classList.remove('no-scroll')
        navigate('/' + window.location.search)
        setRequested(false)
    }

    return (
        <section className={"product-detail-wrapper js-productDetail" + (hash ? ' show' : '')}>
            <div onClick={goBack} className="overlay"></div>
            <figure className="js-productDetailModal">
                <img onClick={goBack} src={arrowUp} alt="Go Back" className="back-button"/>
                <div className="product-image" style={{
                        backgroundColor: productDetailInfo.backgroundColor,
                        color: productDetailInfo.backgroundColorValue === 'Easter Blue' ? '#fff' : ''
                    }}>
                    <picture>
                        <img alt="product" src={productDetailInfo.img} width="225" height="174"/>
                    </picture>
                    {(productDetailInfo.discountLabel) &&
                        <span className="discount-label body-2">
                            {
                                productDetailInfo.discountLabel.toLowerCase().endsWith('sobre pvpr')
                                ? productDetailInfo.discountLabel.slice(0, 'sobre pvpr'.length * -1).trim()
                                : productDetailInfo.discountLabel
                            }
                            {productDetailInfo.discountLabel.toLowerCase().endsWith('sobre pvpr') &&
                                <span className="pvpr">{productDetailInfo.discountLabel.slice('sobre pvpr'.length * -1).trim()}</span>
                            }
                        </span>
                    }
                    { /* productDetailInfo.time &&
                        <div className="timer">
                            <img src={timeIcon} alt="Time"/>
                            <span className="footnote-1">{productDetailInfo.time}</span>
                        </div>
                    */ }
                </div>
                <figcaption>
                    <h1 className="large-title-2">{productDetailInfo.name}</h1>
                    <h2 className="title-6">{productDetailInfo.brand}</h2>
                    {productDetailInfo.weight &&
                    <>
                        <div className="weight-wrapper">
                            <span className="body-5">{productDetailInfo.weight}</span>
                            <span className="body-5">{productDetailInfo.priceWeight}</span>
                        </div>
                    </>
                    }
                    <div className="caption">
                        <div className="price-wrapper">
                            <span className="new-price">
                                {multipliedPrice(productDetailInfo.price[productDetailInfo.price.new !== -1 ? 'new' : 'old'])}
                            </span>
                            {productDetailInfo.price.old !== 0 &&
                                <span className="old-price headline-2">
                                    {productDetailInfo.price.new !== -1 ? multipliedPrice(productDetailInfo.price.old) : ''}
                                </span>
                            }
                        </div>
                        <QuantityInput valueFunc={getChildValue} product={{data: productDetailInfo}} bulk={productInfo.soldInBulk}/>
                    </div>
                    <div className="buttons-wrapper">
                        <ShareBtn/>
                        <button className="btn btn-blue body-4" onClick={addItem}>
                            Adicionar
                        </button>
                    </div>
                </figcaption>
            </figure>
        </section>
    )
}

export default ProductDetail;
