import MainNav from './components/mainNav'
import Loader from './components/loader'
import Content from './views/content'
import './App.sass'
import './libs/cookie.min.css'
import {BrowserRouter} from 'react-router-dom'
import React from 'react'
import {getCookie, setCookie, endpoint} from './utilities/helpers'

import { Provider } from 'react-redux'
import { store } from './utilities/store'

function App() {
    fetch(endpoint('/api/catalogue/get/id'))
      .then(response => response.json())
      .then(data => {
        let newId;
        let cookie = getCookie('catalogID');
        newId = data;
        if(cookie) {
            if (cookie !== newId) {
                setCookie('catalogID', newId);
                localStorage.clear();
            }
        }
        else {
            setCookie('catalogID', newId);
            localStorage.clear();
        }
      })

    
    return (
        <BrowserRouter>
            <Provider store={store}>
                <div className="App">
                    <Loader/>
                    <Content/>
                    <MainNav/>
                </div>
            </Provider>
        </BrowserRouter>
    )
}

export default App;
