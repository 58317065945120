import React from "react"
import { Link } from "react-router-dom"
import backIcon from './../images/vectors/back-arrow.svg'
import eyeIcon from './../images/vectors/eye.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { endpoint } from "../utilities/helpers"
import 'swiper/css'
class Discover extends React.Component {

    constructor() {
        super()
        this.state = {
          icon: 0,
          contents: [],
          page: 0,
          total: 0,
        }

        this.getPage = this.getPage.bind(this);
    }

    getPage(page) {
        const loader = document.querySelector('.loader')

        loader.classList.add('loading')

        fetch(endpoint(`/api/catalogue/getcontent/${page}`))
            .then(response => response.json())
            .then(data => {
                this.setState(prevState => ({
                    contents: prevState.contents.concat(data.cards),
                    page: data.cards.length > 0 ? page : prevState.page,
                    total: data.totalCards
                }))
                loader.classList.remove('loading')
            })
    }

    restartVideo(slider) {
        const allVideos = slider.el.querySelectorAll('video')

        allVideos.forEach(video => {
            video.pause()
            video.currentTime = 0
        })

        setTimeout(() => {
            const activeVideo = document.querySelector('.swiper-slide-active video')
            activeVideo.play()
        },500)
    }

    loadMoreSliders(slider) {
        const slides = slider.slides.filter(slide => slide.classList.value.indexOf('swiper-slide-duplicate') < 0)

        if (
            (slider.isEnd || slider.activeIndex >= slides.length)
            && slides.length < this.state.total
        ) {
            this.getPage(this.state.page + 1)
        }
    }

    componentDidMount() {
        this.getPage(1)
    }

    componentDidUpdate() {
        const allVideos = document.querySelectorAll('video')

        allVideos.forEach(video => {
            const durationContainer = video.closest('.slide').querySelector('.video-duration')

            video.addEventListener('loadeddata', () => {
                const minutes = parseInt(video.duration / 60, 10)
                const seconds = parseInt(video.duration % 60, 10)

                durationContainer.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            })

            video.addEventListener('timeupdate', () => {
                const currentTime = video.duration - video.currentTime
                const minutes = parseInt(currentTime / 60, 10)
                const seconds = parseInt(currentTime % 60, 10)

                durationContainer.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            })
        })
    }

    render() {

        setTimeout(() => document.querySelector('.main-nav').classList.add('going-down'),10)
        const coverSliders = this.state.contents.map((content,id) => 
            <SwiperSlide key={id} className="slide">
                <span className="video-duration body-5"></span>
                <figure>
                    <video playsInline autoPlay loop>
                        <source src={content.recipe.videoUrl}/>
                    </video>
                </figure>
                <figcaption>
                    
                    <div className="content-wrapper">
                        <p className="body-3">{content.recipe.title}</p>
                        <p className="footnote-5">{content.recipe.author}</p>
                    </div>

                    { content.recipe.products.length > 0 &&
                        <Link to={`/promocoes-folheto${window.location.search}`}
                        state={content.recipe}
                        className="see-more-btn"
                        >
                            <img src={eyeIcon} alt="see more" />
                        </Link>
                    }

                </figcaption>
            </SwiperSlide>
        )
        return(
            <section className="video-slider">
                <Link to={`/${window.location.search}`} className="back-btn">
                    <img src={backIcon} alt="go back" />
                </Link>
                <Swiper
                    onSlideChange={swiper => this.restartVideo(swiper)}
                    onSlideNextTransitionStart={swiper => this.loadMoreSliders(swiper)}
                    direction={'vertical'}
                    loop
                    initialSlide={1}
                >
                {coverSliders}
                </Swiper>
            </section>
        )
    }
}

export default Discover;
