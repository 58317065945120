import React from 'react'

class Loader extends React.Component {       
    render() {
        return (
            <div className="loader">
                <img src="logo.png" alt="Logo Minipreço" width="96" height="80"/>
                <div className="loader-bar">
                    <span className="loader-bar-color"></span>
                </div>
            </div>
        )
    }
}

export default Loader