import React, {useEffect, useState} from "react"
import { useNavigate, useLocation } from "react-router-dom"
import notFoundIcon from './../images/vectors/find-error.svg'
import backIcon from './../images/vectors/back-arrow.svg'
import closeIcon from './../images/vectors/close-button.svg'
import SearchCollection from "../components/searchCollection"
import { endpoint } from "../utilities/helpers"
import ProductDetail from "./productDetail"

function Search() {
  
  const [prodID, setID] = useState('')

  let path = useLocation()

  useEffect(() => {
    setID(path.pathname.split('/promocao-produto-')[1])
    document.body.classList.toggle('no-scroll',path.pathname.includes('/promocao-produto-'))
  },[path])

  const [searchResults, setResults] = useState([]),
        [hasContent, setHasContent] = useState(false),      
        navigate = useNavigate()

  const searchRequest = () => {
    const input = document.querySelector('.js-searchInput'),
          searchTerm = input.value

    if (searchTerm.length > 2) {
      const loader = document.querySelector('.loader');

      loader.classList.add('loading')

      fetch(endpoint('/api/search', {
        term: searchTerm,
        page: 1,
        nResults: 5
      }))
      .then(response => response.json())
      .then(result => {
          if (result.results !== undefined) {
            setResults(result.results);
            setHasContent(true)
          }
          else {
            setHasContent(false)
          }

          loader.classList.remove('loading')
      })
    }
    else {
      setHasContent(false)
    }
  }

  const clearSearch = () => {
    document.querySelector('.js-searchInput').value = ''
    setHasContent(false)
  }

  useEffect(() => {
    document.querySelector('.js-searchInput').focus()
  }, [])

  const collectionList = searchResults.map((collection, id) => 
    <SearchCollection
      cardList={collection}
      key={id}
    />
  )

  
  return(
    <>
      <section className="search-wrapper">
        <header>
            <img src={backIcon} onClick={() => navigate(-1)} alt="Go Back" className="back-button" />
          <input type="text" onKeyUp={searchRequest} className="js-searchInput" placeholder="Pesquisar" />
          <button onClick={clearSearch}>
            <img src={closeIcon} alt="clear search" className="close-button" />
          </button>
        </header>
        <main>
          {hasContent
            ? 
              <>
                <div className="card-list">
                  {collectionList}
                </div>
              </>
            :
              <>
                <div className="wrapper-not-found">
                  <img className="icon-not-found" src={notFoundIcon} alt="results not found" />
                  <p className="title-6">Não foram encontrados resultados</p>
                </div>
              </>
          }
          
        </main>
      </section>
      <ProductDetail hash={prodID}/>
    </>
  )
}

export default Search;
