import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import backIcon from './../images/vectors/back-arrow.svg'
import ListCard from "../components/listCard"
import Tabs from "../components/tabs"
import ShareBtn from "../components/share-btn"
import { useLocation } from 'react-router-dom'
import ProductDetail from "./productDetail"
import {store} from '../utilities/store'
import {getCartQtt} from "../utilities/helpers"

const ProductList = () => {  


    const navigate = useNavigate()
    const location = useLocation()
    const [productId, setId] = useState(0)
    const [idToPass, setIdToPass] = useState(0)

    let cards, title, discountValue, description, images, saleName;

    console.log(location.pathname.split('/promocoes-folheto-')[1]);
    if(!useLocation().state && !location.pathname.split('/promocoes-folheto-')[1]) {
      window.location.href = '/'
    } else if(location.pathname.split('/promocoes-folheto-')[1]) {

      // FUTURE TODO - direct links to list products

    } else {
      cards = location.state.products
      title = location.state.title
      discountValue = location.state.discountValue
      description = location.state.description
      images = location.state.imageUrl
      
      saleName = location.state.saleName
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });

    setTimeout(() => document.querySelector('.main-nav').classList.add('going-down'),10)

    

    useEffect(()=> {
      setIdToPass(productId)
    },[productId])

    const cardList = cards.map((card,id) => 
      <div
      key={id}
      onClick={() => {
        setId(card.id)
        document.querySelector('.product-detail-wrapper').classList.add('show')
        window.history.pushState('product detail','','/promocao-produto-' + card.id + window.location.search)
        document.querySelector('body').classList.add('no-scroll');

        // Remove Share when native share doesn't exist
        if (!navigator.share) {
          const share = document.querySelector('.share-button');
    
          if (share)
            share.remove();
        }
      }}>
        <ListCard 
          list={true}
          data={card} 
          discountValue={discountValue}/>
      </div>
    )

    const addAll = () => {
      cards.forEach(card => {
        store.dispatch({type: 'addToCart', cart: 'cart', id: card.id, data: card})
      })
      
      document.querySelector('.cart-count').innerHTML = getCartQtt('cart')
      document.body.classList.remove('no-scroll')
      navigate('/' + window.location.search)
    }

    const goBack = () => {

      if (!document.querySelector('.product-list-wrapper') && !document.querySelector('.search-wrapper')) {
          if (location.pathname !== '/')
              navigate('/' + window.location.search)
          else {
              document.querySelector('.product-detail-wrapper').classList.remove('show')
          }
      }

      if (document.querySelector('.search-wrapper')) {
          navigate(-1)
      }

      
      const body = document.querySelector('body'),
          mainNav = document.querySelector('.main-nav');

      body.classList.remove('no-scroll')

      mainNav.classList.remove('going-down')
      document.querySelector('.product-detail-wrapper').classList.remove('show')
  }

    return(
      <>
        <section className="product-list-wrapper">
          <header>
            <Link to={`/${location.search}`} onClick={goBack}>
              <img src={backIcon} alt="Go Back" className="back-button" />
            </Link>
            <p className="body-5">{title ? title : saleName}</p>
            {(description && description !== '') &&
              <ShareBtn/>
            }
          </header>
          <main>
            {(description && description !== '')
              ?
                <>
                  <Tabs firstTab="Ingredientes" secondTab="Descrição"/>
                  <div className="content-wrapper">
                    <div className="content is-active" data-id="list">
                      {cardList}
                    </div>
                    <div className="content" data-id="description">
                      <figure>
                        <picture>
                          <img src={images.hero} alt="product" />
                        </picture>
                        <figcaption dangerouslySetInnerHTML={{__html: description}}></figcaption>
                      </figure>
                    </div>
                  </div>
                </>
              :
              cardList
            }
          </main>
        </section>
        
      <ProductDetail hash={idToPass} />
      { !saleName &&
        <button className="add-all-button" onClick={addAll}>{'Adicionar (' + cards.length + ') Produtos'}</button>
      }  
      </>

      
    )
}

export default ProductList;
